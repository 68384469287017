.carouselContainer {
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carouselWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}


.carouselContent {
    display: inline-flex;
}

/* Carousel.module.css adjustments for fade effect */
.carouselContentWrapper {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carouselImage {
    position: absolute;
    opacity: 0;
    transition: opacity 0.5s ease;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.carouselImage.active {
    opacity: 1;
}


.leftArrow,
.rightArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #027BFF; /* Blue background */
    color: #FFFFFF; /* White arrow */
    border: none;
    cursor: pointer;
    z-index: 2;
    border-radius: 50%; /* Makes the button circular */
    width: 30px; /* Adjust size as needed */
    height: 30px; /* Adjust size as needed */
    display: flex;
    justify-content: center;
    padding-bottom: 12px;
    align-items: center;
}

.leftArrow {
    left: 10px;
}

.rightArrow {
    right: 10px;
}