/* src/styles.css */
.mobileImage {
    width: 100%;
    max-width: 100%;
    height: 200px;
    display: block;
    margin-top: 10px;
  }

  .desktopImage {
    width: 100%;
    max-width: 100%;
    height: auto;
    display: block;
  }
  
  /* Home.module.css */
  .topRow {
    display: flex;
    justify-content: flex-end;
    padding: 10px; /* Adjust as needed */
  }

  .userGreeting {
    display: flex;
    align-items: center; /* This will vertically align items in the middle */
    justify-content: space-between; /* This will add space between the paragraph and button */
    gap: 10px; /* Optional: adds some space between items */
  }

  .userGreeting p {
    margin: 0; /* Reset default margin */
    display: flex; /* Make the paragraph itself a flex container */
    align-items: center; /* Align its content (text) vertically */
  }

  .roundedBorder {
    border: 1px solid #b8b4b4;
    border-radius: 10px;
    box-sizing: border-box;
  }

  .overlayContainer {
    margin-top: 5px;
    position: relative; /* This makes it the reference for absolutely positioned child elements */
  }

  .overlayText {
    position: absolute;
    bottom: 10px; /* Adjusted to create a vertical gap from the bottom */
    left: 50%; /* Center the element horizontally */
    transform: translateX(-50%); /* Ensure it's centered with respect to its width */
    width: calc(100% - 100px); /* Creates a horizontal gap by reducing total width, adjust 20px as needed */
    background-color: rgba(255, 255, 255, 0.8); /* Adjust the background color opacity as needed */
    padding: 3px; /* Adds padding inside the text box */
    padding-top: 8px;
    margin: 0 auto; /* Adds automatic horizontal margins, can be adjusted if needed */
    border-radius: 999px; /* Rounded corners for the text background */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: Adds a subtle shadow for depth, adjust as needed */
    text-align: center; /* Ensures the text is centered */  
  }

  .mobileText {
    padding: 5px;
    margin-top: 8px;
    text-align: center; /* Ensures the text is centered */  
  }

  .storeButton {
    width: 150px;
    height: auto;
    margin: 0 8px;
  }

  @media (max-width: 768px) {
    .overlayText h4 {
      font-size: 14px; /* Smaller font size for mobile */
    }
  }
  
  