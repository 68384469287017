.title {
    font-weight: bold;
    font-size: 30px;
    text-align: center; /* Centers the text horizontally */
    margin-top: 20px; /* Optional: Adds some space above the text */
}

.desktopBox {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 5px;
  }

  .textInput {
    background-color: #EDF6FF;;
  }

  .loaderWrapper {
    flex: 0 0 auto; /* Prevent loader from stretching */
    margin-top: 10px;
  }
  