.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    gap: 20px; /* Space between links */
  }
  
  .footerLink {
    color: blue;
    text-decoration: underline;
    font-size: 16px;
  }