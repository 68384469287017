.userProfileSetup {
    width: 100%;
    padding: 0 20px 20px;
}

.nameInput {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
}

.locationInput {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
}

.categoryParent {
    width: 100%;
    padding: 20px;
    margin-top: 20px;
    box-sizing: border-box;
}

.title {
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    /* Centers the text horizontally */
}

.categoryContainer {
    gap: 10px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    /* adjust minmax values as needed */
    padding-top: 30px;
    grid-gap: 20px;
    /* space between grid items */
}

.categoryItem {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    text-align: center;
}

.categoryItem.selected {
    background-color: #89CFF0;
    color: white;
}

.submitButton {
    display: block;
    /* Makes the button a block element */
    margin: 20px auto 0;
    /* Top margin 20px, horizontally centered */
    margin-top: 80px;

}

.emailSubscription {
    margin-top: 20px;
    display: flex;
    align-items: center;
    /* Ensures the label and checkbox are vertically aligned */
    justify-content: start;
    /* Aligns content to the start, keeping it tight and organized */
    padding: 10px;
    /* Adds some padding for spacing around the content */
}

.emailSubscription input[type="checkbox"] {
    margin-right: 10px;
    /* Adds spacing between the checkbox and the label */
    cursor: pointer;
    /* Changes the cursor to a pointer to indicate clickable area */
    flex-grow: 0;
    /* Prevents the checkbox from growing */
    flex-shrink: 0;
    /* Prevents the checkbox from shrinking */
    width: 50px;
}

.emailSubscription label {
    cursor: pointer;
    /* Ensures the label is also clickable, improving UX */
    font-size: 16px;
    /* Adjust font size for better readability */
    color: #333;
    /* A neutral color for the text, ensuring good contrast */
}

.inputField {
    background-color: #EDF6FF;
    ;
}

.feedbackMessage {
    display: flex;
    justify-content: center;
    /* Aligns children (text) horizontally center */
}

.emoji {
    font-size: 24px;
    background: transparent;
}

.pastMomentsInput {
    margin-top: 20px;
    margin-bottom: 1rem;
}

.textareaField {
    width: 100%;
    height: 150px;
    padding: 0.5rem;
    margin-top: 0.25rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}