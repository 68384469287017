/* DropdownMenu.module.css */
.dropdownMenu {
    position: absolute;
    top: 40px; /* Adjust based on the exact positioning of your settings icon */
    right: 10px;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    z-index: 1000;
    padding: 10px;
  }
  
  .dropdownItem {
    display: block;
    padding: 10px 20px;
    margin-top: 5px;
    width: 100%;
    border: none;
    text-align: center;
    cursor: pointer;
    border-radius: 999px;
  }
  
  .dropdownItem:hover {
    background-color: #0069D9;
  }
  
  /* SettingsIcon.module.css */
  .settingsContainer {
    position: relative; /* Needed to position the dropdown menu absolutely relative to this container */
    cursor: pointer;
  }
  