.headerContainer {
    display: flex;
    flex-direction: column; /* Stack content vertically */
    padding: 10px 6px 8px;
}

.topRow {
    display: flex;
    justify-content: space-between; /* Align items horizontally */
    align-items: center; /* Center items vertically */
}

.companyName {
    font-weight: bold;
    font-size: 20px;
    /* Adjust size as needed */
}

.actionButtons {
    display: flex;
    align-items: center; /* Center the buttons vertically */
    gap: 5px; /* Adds space between the buttons */
}


.logoutButton, .loginButton {
    padding: 5px 10px;
    /* Adjust padding as needed */
    font-size: 16px;
    /* Adjust font size as needed */
    cursor: pointer;
    /* Changes cursor to pointer to indicate clickable */
}

.welcomeTextRow {
    font-size: 12px; /* Adjust as per design requirements */
    text-align: center; /* Centers the text */
}

.usernameRow {
    font-size: 12px; /* Adjust as per design requirements */
    text-align: center; /* Centers the text */
    color: #027BFF;
}

.settingsButton {
    /* Styles for your settings button */
    background: none;
    border: none;
    cursor: pointer;
    color: #808080;
    /* ... other styles ... */
  }
