body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

#root {
    width: 100%
}

div {
    background-color: #ffffff;
}

.centered-container {
    display: flex;
    flex-direction: column;
    /* This will stack children vertically */
    justify-content: center;
    align-items: center;
    height: 100%;
}

.centered-horizontal {
    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
}

.centered-screen {
    display: flex;
    flex-direction: column;
    /* This will stack children vertically */
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.text-left-align {
    align-self: flex-start; /* This aligns the div and its content to the start (left) of the flex container */
    width: 100%; /* Ensures the div stretches across the full width of the parent container */
}

input {
    width: 100%;
    padding: 10px 15px;
    border-radius: 4px;
    border: 0.5px solid #e0e0e0;
    margin-bottom: 20px;
    font-size: 16px;
}

h2 {
    margin-bottom: 20px;
}

.blueText {
    color: blue;
  }

  .greenText {
    color: rgb(150, 11, 131);
  }