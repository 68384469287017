.centeredScreen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .heading {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .description {
    font-size: 1rem;
    margin-bottom: 1.5rem;
    color: #555;
  }
  
  .button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .danger {
    background-color: #e74c3c;
    color: white;
  }
  
  .confirm {
    background-color: #27ae60;
    color: white;
  }
  
  .cancel {
    background-color: #95a5a6;
    color: white;
  }
  
  .dialogOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .dialogBox {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 400px;
    width: 90%;
  }
  
  .dialogTitle {
    font-size: 1.25rem;
    margin-bottom: 1rem;
    color: #333;
  }
  
  .dialogActions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
  }
  
  .statusMessage {
    margin-top: 20px;
    font-size: 1rem;
    text-align: center;
  }
  
  .success {
    color: green;
  }
  
  .error {
    color: red;
  }