button {
  background-color: #007BFF;
  color: #ffffff;
  padding: 8px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.custom-button {
  background-color: #007BFF;
  color: #ffffff;
  padding: 3px 12px;
  border: none;
  border-radius: 999px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #0056b3;
}

button:active {
  background-color: #003d7a;
}

button:disabled {
  background-color: #a0a0a0;
  cursor: not-allowed;
}

.subtleButton {
  background-color: #f0f0f0;
  /* Light grey background */
  color: #333333;
  /* Darker text for contrast */
  padding: 10px 15px;
  border: 1px solid #d1d1d1;
  /* Slight border for definition */
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.subtleButton:hover {
  background-color: #e0e0e0;
  /* Slightly darker on hover */
}

.subtleButton:active {
  background-color: #c0c0c0;
  /* Even darker when active/clicked */
}

.subtleButton:disabled {
  background-color: #e0e0e0;
  cursor: not-allowed;
}